import { FunctionComponent, useState } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { createShowFieldValidation } from '@templafy/ui/utilities/showFieldValidation';
import { useFormValidation } from '@templafy/validator';
import { Button } from '@templafy/ui/components/button/Button';
import { useTranslator } from '@templafy/translation';
import { useAsyncEffect } from '@templafy/ui/hooks/useAsyncEffect';
import { Form } from '@templafy/ui/components/form/Form';
import { TextInput } from '@templafy/ui/components/textInput/TextInput';
import { PasswordInput } from '@templafy/ui/components/passwordInput/PasswordInput';
import { useParams } from 'react-router-dom';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { UserActivationDto } from '../generated/Contracts';
import { validatePassword } from '../utilities/passwordValidation';
import { Api } from '../generated/Api';

const useVerifyActivation = (activationId: string) => {
  useAsyncEffect(async () => {
    const response = await Api.UserActivation.verifyPendingUser(activationId);
    if (response.location != null) {
      globalThis.location.assign(response.location);
    }
  }, [activationId]);
};

export const UserActivationPage: FunctionComponent = () => {
  const { activationId } = useParams();
  useVerifyActivation(activationId ?? '');

  const translator = useTranslator();
  const [isLoading, setIsLoading] = useState(false);
  const [formState, setFormValue, submit] =
    useFormValidation<UserActivationDto>(UserActivationDto, {
      values: {
        activationId,
      },
      async onSubmit(values) {
        try {
          setIsLoading(true);
          const response = await Api.UserActivation.activateUser(values);
          globalThis.location.replace(response.location);
          // eslint-disable-next-line no-empty
        } catch {
        } finally {
          setIsLoading(false);
        }
      },
      validation: (form, addValidation) => {
        if (form.password !== form.confirmPassword) {
          addValidation(
            'confirmPassword',
            translator.validationPasswordEqualsConfirmPassword(),
          );
        }

        // eslint-disable-next-line @typescript-eslint/no-base-to-string
        if (form.password && !validatePassword(form.password.toString())) {
          addValidation(
            'password',
            translator.validationPasswordRequirements(),
          );
        }
      },
    });

  const showFieldValidation = createShowFieldValidation({
    formState,
    setFormValue,
  });

  const firstNameFieldValidation = showFieldValidation('firstName');
  const lastNameFieldValidation = showFieldValidation('lastName');
  const passwordFieldValidation = showFieldValidation('password');
  const confirmPasswordFieldValidation = showFieldValidation('confirmPassword');

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <Text as="h1" styleAs="p">
          {translator.userActivationPageVerificationMessage()}
        </Text>

        <Form id="user-activation-page-form" onSubmit={submit} size="large">
          <Form.Field>
            <Form.Label>
              {translator.userActivationPageFirstNameLabel()}
            </Form.Label>
            <TextInput
              {...firstNameFieldValidation}
              autoFocus
              placeholder={translator.userActivationPageFirstNamePlaceholder()}
            />
            <Form.ValidationMessage>
              {firstNameFieldValidation.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Form.Field>
            <Form.Label>
              {translator.userActivationPageLastNameLabel()}
            </Form.Label>
            <TextInput
              {...lastNameFieldValidation}
              placeholder={translator.userActivationPageLastNamePlaceholder()}
            />
            <Form.ValidationMessage>
              {lastNameFieldValidation.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Form.Field>
            <Form.Label
              tooltip={translator.userActivationPagePasswordTooltip()}
            >
              {translator.userActivationPagePasswordLabel()}
            </Form.Label>
            <PasswordInput
              {...passwordFieldValidation}
              placeholder={translator.userActivationPagePasswordPlaceholder()}
            />
            <Form.ValidationMessage>
              {passwordFieldValidation.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Form.Field>
            <Form.Label>
              {translator.userActivationPageConfirmPasswordLabel()}
            </Form.Label>
            <PasswordInput
              {...confirmPasswordFieldValidation}
              placeholder={translator.userActivationPageConfirmPasswordPlaceholder()}
            />
            <Form.ValidationMessage>
              {confirmPasswordFieldValidation.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Button type="submit" action="primary" loading={isLoading}>
            {translator.userActivationPageFinishSignupButtonText()}
          </Button>
        </Form>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
