import { FunctionComponent } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { Button } from '@templafy/ui/components/button/Button';
import { useTranslator } from '@templafy/translation';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';

const redirectToLoginPage = () => {
  globalThis.location.replace(`/auth/email/login${globalThis.location.search}`);
};

export const PasswordResetLinkSentPage: FunctionComponent = () => {
  const translator = useTranslator();

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <Text as="h1" styleAs="p">
          {translator.passwordResetLinkSentPageDescription()}
        </Text>
        <Button
          onClick={redirectToLoginPage}
          action="primary"
          style={{ marginTop: '8px', flexDirection: 'column' }}
        >
          {translator.passwordResetLinkSentPageLoginButton()}
        </Button>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
