import { FunctionComponent, useEffect } from 'react';
import { Text } from '@templafy/ui/components/text/Text';
import { NotificationService } from '@templafy/notifications';
import { Button } from '@templafy/ui/components/button/Button';
import { useTranslator } from '@templafy/translation';
import { Form } from '@templafy/ui/components/form/Form';
import { zodResolver } from '@hookform/resolvers/zod';
import { defaultFormProps, getManager } from '@templafy/ui/reactHookForm';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { ManagedPasswordInput } from '@templafy/ui/reactHookForm/ManagedPasswordInput';
import {
  AuthenticationLayout,
  AuthenticationBox,
} from './layout/AuthenticationLayout';
import { Api } from '../generated/Api';
import { passwordRegex } from '../utilities/passwordValidation';
import { useQueryParameters } from '@/hooks/useQueryParameters';

export const ResetPasswordPage: FunctionComponent = () => {
  const queryParameters = useQueryParameters();
  const passwordResetId = queryParameters.get('passwordResetId') ?? '';
  const redirectUri = queryParameters.get('redirect_uri') ?? undefined;
  const translator = useTranslator();

  const schema = z
    .object({
      password: z
        .string()
        .regex(passwordRegex, translator.validationPasswordRequirements()),
      confirmPassword: z.string(),
    })
    .refine((data) => data.password === data.confirmPassword, {
      message: translator.validationPasswordEqualsConfirmPassword(),
      path: ['confirmPassword'],
    });

  type FormModel = z.infer<typeof schema>;

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormModel>({
    ...defaultFormProps,
    resolver: zodResolver(schema),
  });

  const manage = getManager(control, schema);

  const submit = handleSubmit(async (data) => {
    try {
      const response = await Api.Password.resetPassword({
        ...data,
        passwordResetId,
        redirectUri,
      });

      NotificationService.success({
        title: translator.resetPasswordPageSuccessNotificationTitle(),
        description:
          translator.resetPasswordPageSuccessNotificationDescription(),
      });

      if (response.location != null) {
        globalThis.location.assign(response.location);
      }
    } catch {
      return false;
    }
  });

  useEffect(() => {
    const validatePasswordLink = async () => {
      if (passwordResetId == null) {
        return;
      }

      const response =
        await Api.Password.validateResetPasswordLink(passwordResetId);

      if (response?.location != null) {
        globalThis.location.assign(response.location);
      }
    };

    void validatePasswordLink();
  }, [passwordResetId]);

  return (
    <AuthenticationLayout>
      <AuthenticationBox>
        <Form
          id="reset-password-page-form"
          onSubmit={submit}
          size="large"
          readOnly={isSubmitting}
        >
          <Text as="h1" size="300">
            {translator.resetPasswordPageDescription()}
          </Text>
          <Form.Field>
            <Form.Label tooltip={translator.passwordFieldTooltip()}>
              {translator.passwordFieldLabel()}
            </Form.Label>
            <ManagedPasswordInput
              {...manage('password')}
              placeholder={translator.passwordFieldPlaceholder()}
              autoFocus
            />
            <Form.ValidationMessage>
              {errors.password?.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Form.Field>
            <Form.Label>{translator.confirmPasswordFieldLabel()}</Form.Label>
            <ManagedPasswordInput
              {...manage('confirmPassword')}
              placeholder={translator.confirmPasswordFieldPlaceholder()}
            />
            <Form.ValidationMessage>
              {errors.confirmPassword?.message}
            </Form.ValidationMessage>
          </Form.Field>
          <Button
            type="submit"
            action="primary"
            loading={isSubmitting}
            size="large"
          >
            {translator.resetPasswordPageSubmitButton()}
          </Button>
        </Form>
      </AuthenticationBox>
    </AuthenticationLayout>
  );
};
